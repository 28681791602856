import React, {useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import Select from "react-select";
import setAxiosHeaders from "../../../components/AxiosHeaders";
import axios from "axios";

export const ProductVariantSelector = (props) =>
{
    const selectInputRef = useRef();
    const initialLoad = useRef(true);
    const initialColorLoad = useRef(true);

    const [product_id, setProduct_Id] = useState(props.product_id ? props.product_id : '');
    const [externalProductId, setExternalProductId] = useState(props.external_id);
    const [externalVariantId, setExternalVariantId] = useState(props.external_variant_id);
    const [productTypeId, setProductTypeId] = React.useState(props.data.product_type_id ? props.data.product_type_id : '');
    const [selectedProductVariantId, setSelectedProductVariantId] = useState('');


    const [selectedColor, setSelectedColor] = useState(props.data.product_color ? props.data.product_color : '');
    const [availableColors, setAvailableColors] = useState([]);
    const [selectableColors, setSelectableColors] = useState([]);

    const [selectedSize, setSelectedSize] = useState(props.data.size ? props.data.size : '');
    const [availableSizes, setAvailableSizes] = useState([]);
    const [selectableSizes, setSelectableSizes] = useState([]);
    const isWarningVisible = !selectedColor || !selectedSize;
    const [isAddToCartEnabled, setAddToCartEnabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleModalShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    React.useEffect(() => {
        const addToCartButton = document.getElementById('addToCartButton');
        // The button should be enabled only if both the selected size and color are available
        addToCartButton.disabled = !selectableSizes.includes(selectedSize) || !selectableColors.includes(selectedColor);
    }, [selectedColor, selectedSize, selectableSizes, selectableColors]);

    const loadColorSwatches = (productTypeId, productVariantId) => {
        setAxiosHeaders();
        const colorSwatchesURL = '/api/v1/product_types/' + productTypeId + '/product_variants/colors';

        axios
            .get(colorSwatchesURL)
            .then(response => {
                const colorSwatches = response.data;
                setAvailableColors(colorSwatches);
                if (initialColorLoad.current) {
                    setSelectableColors(colorSwatches.map(color => color.name));
                    initialColorLoad.current = false;  // Set it to false so it won't run again
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const loadAvailableSizes = (productTypeId, productVariantId) => {
        setAxiosHeaders();
        const availableSizesURL = '/api/v1/product_types/' + productTypeId + '/product_variants/sizes';

        axios
            .get(availableSizesURL)
            .then(response => {
                const availableSizes = response.data;
                setAvailableSizes(availableSizes);
                if (initialLoad.current) {
                    setSelectableSizes(availableSizes.map(sizeObj => sizeObj.size));
                    initialLoad.current = false;  // Set it to false so it won't run again
                }

            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <div className="row product-selector">
            <ProductVariantEffects
                productTypeId={productTypeId}
                selectedColor={selectedColor}
                selectedSize={selectedSize}
                selectableSizes={selectableSizes}
                selectableColors={selectableColors}
                setSelectedProductVariantId={setSelectedProductVariantId}
                selectedProductVariantId={selectedProductVariantId}
                setSelectableColors={setSelectableColors}
                setSelectableSizes={setSelectableSizes}
                setExternalVariantId={setExternalVariantId}
                externalVariantId={externalVariantId}
                loadColorSwatches={loadColorSwatches}
                loadAvailableSizes={loadAvailableSizes}
                isAddToCartEnabled={isAddToCartEnabled}
                setAddToCartEnabled={setAddToCartEnabled}
            />
            <div className="product-selector_title">
                <SizeSelector
                    key={selectableSizes.join('-')} // Add this line
                    productTypeId={productTypeId}
                    selectedProductVariantId={selectedProductVariantId}
                    availableSizes={availableSizes}
                    setSelectedSize={setSelectedSize}
                    selectableSizes={selectableSizes}
                    selectInputRef={selectInputRef}
                    selectedSize={selectedSize}
                    handleModalShow={handleModalShow}
                />

                <ColorSelector
                    productTypeId={productTypeId}
                    selectedProductVariantId={selectedProductVariantId}
                    selectedColor={selectedColor}
                    availableColors={availableColors}
                    setAvailableColors={setAvailableColors}
                    setSelectedColor={setSelectedColor}
                    selectableColors={selectableColors}
                    handleModalShow={handleModalShow}
                    setSelectedSize={setSelectedSize}
                />
                <Modal show={showModal} onClose={() => setShowModal(false)}>
                    <p>{modalMessage}</p>
                </Modal>

                <input type="hidden" name="product_variant_id" id="product_variant_id" value={selectedProductVariantId} />
                {!isAddToCartEnabled && <CartWarning isWarningVisible={isWarningVisible}/>}

            </div>



        </div>
    )
}

const ProductVariantEffects = ({
                                   productTypeId,
                                   selectedColor,
                                   selectableColors,
                                   selectableSizes,
                                   selectedSize,
                                   setSelectedProductVariantId,
                                   selectedProductVariantId,
                                   setExternalVariantId,
                                   externalVariantId,
                                   loadColorSwatches,
                                   loadAvailableSizes,
                                   setSelectableColors,
                                   setSelectableSizes,
                                   setAddToCartEnabled
                               }) => {
    setAxiosHeaders();

    React.useEffect(() => {
        // Get the available Colors for the selected Size
        if (selectedSize) {
            const availableColorsForSelectedSizeURL = `/api/v1/product_types/${productTypeId}/product_variants/colors?size=${selectedSize}`;
            axios.get(availableColorsForSelectedSizeURL)
                .then(response => {
                    const colors = response.data
                        .filter(colorObj => colorObj.available_sizes.includes(selectedSize))
                        .map(colorObj => colorObj.name);
                    setSelectableColors(colors);
                })
                .catch(error => {
                    console.log("Error fetching colors:", error);
                });
        }
    }, [selectedSize, productTypeId]);


    React.useEffect(() => {
        // Get the available Sizes for the selected Color
        if (selectedColor) {
            const availableSizesForSelectedColorURL = `/api/v1/product_types/${productTypeId}/product_variants/sizes?color=${selectedColor}`;
            axios.get(availableSizesForSelectedColorURL)
                .then(response => {
                    const sizesForSelectedColor = response.data
                        .filter(sizeObj => sizeObj.available_colors.includes(selectedColor))
                        .map(sizeObj => sizeObj.size);
                    setSelectableSizes(sizesForSelectedColor);

                })
                .catch(error => {
                    console.log("Error fetching sizes:", error);
                });
        }
    }, [selectedColor, productTypeId]);

    // Color and Size have changed
    React.useEffect(() => {
        let isMounted = true;  // Initialize the isMounted flag

        setAxiosHeaders()

        // if productTypeId, selectedColor and selectedSize are set, get the product variant
        if (productTypeId && selectedColor && selectedSize) {
            const variantURL = '/api/v1/product_types/' + productTypeId + '/product_variants/?size=' + selectedSize + '&color=' + selectedColor;
            axios
                .get(variantURL)
                .then(response => {
                    if (isMounted) {  // Check if component is still mounted
                        setSelectedProductVariantId(response.data[0].id);
                        setExternalVariantId(response.data[0].printful_variant_id);
                        setAddToCartEnabled(true);
                    }
                })
                .catch(error => {
                    console.log(error);
                });

            // if productTypeId, selectedColor and selectedSize are all set, enable the add to cart button
            const addToCartButton = document.getElementById('addToCartButton');
            addToCartButton.disabled = !selectableSizes.includes(selectedSize) || !selectableColors.includes(selectedColor);

        } else {
            // if productTypeId, selectedColor or selectedSize are not set, disable the add to cart button
            if (isMounted) {  // Check if component is still mounted
                document.getElementById('addToCartButton').disabled = true;
            }
        }

        // Cleanup function to update the isMounted flag when the component is unmounted
        return () => {
            isMounted = false;
        }

    }, [selectedColor, selectedSize])


    // Color has changed
    React.useEffect(() => {
        setAxiosHeaders()

        const variantURL = '/api/v1/product_types/' + productTypeId + '/product_variants/?size=' + selectedSize + '&color=' + selectedColor;
        axios
            .get(variantURL)
            .then(response => {
                setExternalVariantId(response.data[0].printful_variant_id);
            })
            .catch(error => {
                console.log(error);
            });

    }, [selectedColor])

    // External (?) Product Variant ID has changed; update the loaded template
    React.useEffect(() => {
        //loadTemplateInformation(externalProductId, externalVariantId);
        loadColorSwatches(productTypeId, selectedProductVariantId);
        loadAvailableSizes(productTypeId, selectedProductVariantId);
    }, [externalVariantId]);

    return null; // This component does not render anything
};

const CartWarning = ({ isWarningVisible }) => {
    if (!isWarningVisible) return null;

    return (
        <div className="row" id={'cartWarning'}>
            <div className="col-md-12 d-flex justify-content-center">
                <span className={'alert-warning'}>Please select a color and size</span>
            </div>
        </div>
    );
};



const ColorSelector = (props) => {

    const handleColorChange = (event) =>
    {
        const colorName = event.target.dataset.colorName;
        const colorHex = event.target.dataset.colorHex;

        if (!props.selectableColors.includes(colorName)) {
            props.handleModalShow(`${colorName} is currently unavailable in the selected size.`);
            // Reset the selected size since the color is not available
            props.setSelectedSize('');
        } else {
            props.setSelectedColor(colorName);
            // You can also fetch the available sizes for the selected color here
            // And update the selectableSizes state accordingly
        }

        let productElement = document.querySelector("[data-controller='product']");
        //productElement.dataset.colorName = event.target.dataset.colorName;
        productElement.dataset.productColorCodeValue = colorHex;
        console.log(productElement.dataset.productColorCodeValue);
        console.log(productElement);

        props.setSelectedColor(colorName);
        // select class called product-gallery-thumblist-item
        const productGalleryThumblistItems = document.getElementsByClassName('product-gallery-thumblist-item');
        // change the background color of the selected product variant
        for (let i = 0; i < productGalleryThumblistItems.length; i++) {
            productGalleryThumblistItems[i].style.backgroundColor = colorHex;
        }

        // select class called product-gallery-preview-item
        const productGalleryPreviewItems = document.getElementsByClassName('product-photo-large');
        // change the background color of the selected product variant
        for (let i = 0; i < productGalleryPreviewItems.length; i++) {
            productGalleryPreviewItems[i].style.backgroundColor = colorHex;
        }

        // Update the background color of the product-gallery-large-photo element
        const productGalleryLargePhoto = document.getElementById('product-gallery-large-photo');
        if (productGalleryLargePhoto) {
            productGalleryLargePhoto.style.backgroundColor = colorHex;
        }



    }

    return (
        <div className="color-selector position-relative me-n4 mb-3">
            <label className="form-label" htmlFor="product-size">Color:</label>
            <ul className={"list-group list-group-horizontal productColorSwatch"} id={"product-color"} style={{flexWrap: 'wrap'}}>
                {props.availableColors.map((color, index) => {
                    const isActive = props.selectableColors.includes(color.name);
                    return (
                        <li key={index} className={"list-group-item border-0"} style={props.selectedColor === color.name ? {backgroundColor: color.hex} : {backgroundColor: '#fff'}}>
                            <div key={index}
                                 className={isActive ? (props.selectedColor === color.name ? "selected-color border p-3" : "p-3 color-selector_swatch form-option-label rounded-circle") : "p-3 color-selector_swatch_disabled form-option-label rounded-circle"}
                                 style={{backgroundColor: color.hex}}
                                 alt={color.name}
                                 data-color-name={color.name}
                                 data-color-hex={color.hex}
                                 data-external-product-id={color.ext_id}
                                 onClick={handleColorChange}
                            >
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}

const SizeSelector = (props) => {

    const options = [];
    props.availableSizes.forEach(sizeObj => {
        const size = sizeObj.size;
        const isDisabled = !props.selectableSizes.includes(size);
        const isUnavailable = !props.selectableSizes.includes(size);
        //options.push({value: size, label: size});
        options.push({
            value: size,
            label: size,
            isUnavailable: isUnavailable,
        });
    });



    const handleSelectSizeChange = (selectedOption) => {
        const size = selectedOption.value;
        if (!props.selectableSizes.includes(size)) {
            props.handleModalShow(`${size} is currently unavailable in the selected color.`);
            return;
        }
        props.setSelectedSize(selectedOption.value);
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.data.isUnavailable ? 'lightgray' : 'default'
        })
    };

    return (
        <div className="size-selector position-relative me-n4 mb-3">

            <div className="d-flex justify-content-between align-items-center pb-1">
                <label className="form-label" htmlFor="product-size">Size:</label>
            </div>
            <div>
            </div>
            <Select
                value={options.find(option => option.value === props.selectedSize)}
                id="product-size"
                required
                onChange={handleSelectSizeChange}
                options={options}
                ref={props.selectInputRef}
                classNamePrefix="product-size"
                styles={customStyles}
            />
        </div>
    )
}

const Modal = ({ show, onClose, children }) => {
    if (!show) return null;

    return (
        <div className={'modal'} style={{display: 'block'}} tabIndex={-1} role={'dialog'} aria-labelledby={'unavailableSize'} aria-hidden={'true'}>
            <div className={'modal-dialog modal-dialog-centered'} role={'document'}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="unavailableLabel">Unavailable</h5>
                        <button type="button" onClick={onClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        {children}
                        <button onClick={onClose}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};





document.addEventListener('turbo:load', () => {
    let element = document.getElementById('product-variant-selector');

    let data = {}
    let external_id = ''
    let external_variant_id = ''
    let product_id = '';
    let current_user_id = '';

    if (element !== null) {
        product_id = element.getAttribute('product_id')
        data = JSON.parse(element.getAttribute('data'))
        external_id = element.getAttribute('external_id')
        external_variant_id = element.getAttribute('external_variant_id')
    }

    if (element) {
        ReactDOM.render(<ProductVariantSelector
            product_id={product_id}
            external_id={external_id}
            external_variant_id={external_variant_id}
            data={data}
            current_user_id={current_user_id}
        />, element);
    }
})